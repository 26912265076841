import Alpine from "alpinejs";
import explosionSound from "./assets/audio/explosion.mp3";
import beepSound from "./assets/audio/beep.mp3";

const explosion = new Audio(explosionSound);

// Importing game logic.
import * as game1 from "./js/game1";
import * as game3 from "./js/game3";
import * as game4 from "./js/game4";

// This file makes sure the HTML drag and drop API is supported on touch devices.
import "./js/draganddrop";

window.Alpine = Alpine;

Alpine.store("textbox_open", false);

Alpine.store("game_states", {
  game_1: {
    active: false,
    completed: false,
  },
  game_2: {
    active: false,
    completed: false,
  },
  game_3: {
    active: false,
    completed: false,
  },
  game_4: {
    active: false,
    completed: false,
  },
  is_completed(game_id) {
    return this[game_id].completed;
  },
  toggle_active(game_id) {
    this[game_id].active = !this[game_id].active;
  },
  go_to_next_game(game_id) {
    if (game_id === "game_4") {
      return;
    }
    const game_ids = Object.keys(this);
    const game_index = game_ids.indexOf(game_id);
    const next_game_id = game_ids[game_index + 1];
    this.toggle_active(game_id);
    this.toggle_active(next_game_id);
  },
});

Alpine.store("help_modal_open", false);
Alpine.store("timer", "10:00");
Alpine.store("game_over", false);
Alpine.store("game_won", false);

Alpine.start();

// When the page is fully loaded, send a POST request to /api/games/start to start the game, and console.log the response.
window.addEventListener("load", async () => {
  const response = await fetch(
    "https://backend-actemium.fly.dev/api/games/start",
    {
      method: "POST",
    }
  );
  const data = await response.json();
  Alpine.store("game_states").toggle_active("game_1");
  console.log(data.message);
});

let countdown = 600;

let timerInterval = setInterval(function () {
  countdown = --countdown;
  if (countdown === 60) {
    // Set the color of id="countdown-number" and the stroke of #countdown svg circle to red
    document.getElementById("countdown-number").style.color = "red";
    document.getElementById("countdown-circle").style.stroke = "red";
  }
  if (countdown === 14) {
    const beep = new Audio(beepSound);
    beep.play();
  }
  if (countdown === 0) {
    clearInterval(timerInterval);
    explosion.play();
    Alpine.store("game_over", true);
  }
  let minutes = Math.floor(countdown / 60);
  let seconds = countdown - minutes * 60;
  const formattedTime = `0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  if (!Alpine.store("game_over") && !Alpine.store("game_won")) {
    Alpine.store("timer", formattedTime);
  } else {
    // Stop the timer
    clearInterval(timerInterval);
  }
}, 1000);

// When the DOM is ready
document.addEventListener("DOMContentLoaded", () => {
  game1.initializeGame();

  document
    .getElementById("g1_check")
    .addEventListener("click", game1.checkGameCompletion);
  document
    .getElementById("g1_reset")
    .addEventListener("click", game1.resetGame);
});

// Game 3 logic
game3.cards.forEach((card) => card.addEventListener("click", game3.flipCard));
