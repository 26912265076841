import Alpine from "alpinejs";
import successSound_ from "../assets/audio/game-finished.mp3";
import scissorsSound_ from "../assets/audio/scissors.mp3";
import explosionSound_ from "../assets/audio/explosion.mp3";
const explosion = new Audio(explosionSound_);
const successSound = new Audio(successSound_);
const scissorsSound = new Audio(scissorsSound_);

const confetti = require("canvas-confetti").default;

const scissors = document.getElementById("scissors");

scissors.addEventListener("dragstart", function (e) {
  // This will make the original element invisible.
  this.style.opacity = "0";
  // Make the dragged element opaque.
  const clone = this.cloneNode(true);
  clone.style.opacity = "1";
  e.dataTransfer.setDragImage(clone, 40, 40);
});

scissors.addEventListener("dragend", function (e) {
  // This will make the original element visible again after dragging.
  this.style.opacity = "1";
});

const correctOrder = ["blue", "green", "cyan", "yellow"];
let cutOrder = [];

function startGame() {
  if (Alpine.store("game_over") || Alpine.store("game_won")) {
    return;
  }
  const wires = document.querySelectorAll(".wire");
  const cutWires = document.querySelectorAll(".cut_wire");
  // Display all the wires and hide all the uncut wires.
  wires.forEach((wire) => {
    wire.style.display = "block";
  });
  cutWires.forEach((cutWire) => {
    cutWire.style.display = "none";
  });
  cutOrder = []; // Empty the array so user can start over.

  wires.forEach((dropzone) => {
    // Remove all existing event listeners from the previous times the game was started.
    dropzone.removeEventListener("dragover", handleDragOver);
    // When a dragged element is hovered over a dropzone, call handleDragOver.
    dropzone.addEventListener("dragover", handleDragOver, { once: true });
  });
}

function confettiAnimation() {
  const duration = 15 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  const randomInRange = (min, max) => Math.random() * (max - min) + min;

  const interval = setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 50 * (timeLeft / duration);

    // since particles fall down, start a bit higher than random
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    });
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    });
  }, 250);
}

function handleDragOver(e) {
  // Play the scissors sound when the user drags over the wire.
  scissorsSound.play();
  // Replace the intact wire with the cut wire on dragover.
  this.style.display = "none";
  const cutWire = document.getElementById(`${this.id}_cut`);
  cutWire.style.display = "block";
  e.preventDefault();
  cutOrder.push(this.dataset.color);
  if (cutOrder.length === 4) {
    // Check whether user has cut wires in correct order, and if there's time left on the timer.
    if (
      cutOrder.join("") === correctOrder.join("") &&
      Alpine.store("timer") !== "00:00" &&
      countdown.innerHtml !== "00:00"
    ) {
      confettiAnimation();
      successSound.play();
      Alpine.store("game_won", true);
    } else {
      // If the user cuts the wires in the wrong order, or if the timer runs out, show the explosion.
      explosion.play();
      Alpine.store("game_over", true);
    }
  }
}

const resetButton = document.getElementById("reset");
resetButton.addEventListener("click", startGame);
startGame();

// TODO
// Show endscreen when the game is completed.
